<template>
  <asom-card :title="`${userGroup} Stations`">
    <asom-client-table 
      :columns="[ 'no', 'stationName', 'formattedShortCode', 'zones' ]"
      :data="proceededStations"
      :sortableColumns="[ 'stationName', 'formattedShortCode', 'zones']"
      tableClasses="tbl_smrtStation"
      :itemsPerPage="5"
    >
      <template v-slot:header_stationName>Name</template>
      <template v-slot:header_formattedShortCode>Station Code</template>
      <template v-slot:no="scopedSlots">
        {{scopedSlots.rowIndex + 1}}
      </template>
      <template v-slot:formattedShortCode="scopedSlots">
        <div class="ShortCodeBlock">
          <div
            v-for="(sc, i) in scopedSlots.rowData.shortCode"
            :class="[ 'ShortCode', `ShortCode--${sc}`]"
            :key="i"
          >
            {{sc}}
          </div>
        </div>
      </template>
    </asom-client-table>
  </asom-card>
  
  <asom-card :title="`${userGroup} Officers`">
    <asom-server-table
      :url="userListUrl"
      :columns="['no', 'personNo', 'name', 'station', 'roles']"
    >
      <template v-slot:header_station>
        Assigned Station
      </template>
      <template v-slot:no="scopedSlots">
        {{ scopedSlots.rowIndex + 1 }}
      </template>
      <template v-slot:roles="scopedSlots">
        {{
          scopedSlots.rowData.roles && scopedSlots.rowData.roles.join(", ")
        }}
      </template>
    </asom-server-table>
  </asom-card>
</template>
<script>
import { LIST_USERS } from "@/constants/apis";
import { mapGetters, mapState } from "vuex";
import sortBy from 'lodash.sortby';

export default {
  computed: {
    ...mapState({
      zones: (state) => state.smrtResource.zones,
      groups: (state) => state.smrtResource.groups,
    }),
    ...mapGetters({
      userGroup: 'auth/userGroupName',
      userGroupId: 'auth/userGroupId',
      userLineId: 'auth/userLineId',
    }),
    proceededStations() {
      const stations = this.$store.getters['smrtResource/stationsByGroupId'](this.userGroupId);
      return sortBy(
        stations.map((station) => {
          return {
            stationName: station.stationName,
            formattedShortCode: this.getStationFormattedShortCodes(station),
            shortCode: this.getStationShortCodes(station),
            zones: this.getStationZoneNames(station),
            group: this.getStationGroupNames(station),
            // line: this.getStationLineNames(station),
          }
        }),
        [ 'zones', 'formattedShoftCode' ]
      );
    },
    userListUrl() {
      return LIST_USERS + `?groupId=${this.userGroupId}&lineId=${this.userLineId}`;
    }
  },
  
  methods: {
    getStationShortCodes({ zoneStations = []}) {
      const codes = zoneStations.map(zs => zs.code);
      return [ ...new Set(codes) ].sort();
    },
    getStationFormattedShortCodes(station) {
      const codes = this.getStationShortCodes(station)
      const formattedCodes = codes.map(code => {
        const numCode = code.substr(-2); // e.g 'C1' for CC1, '10' for CC10
        if (Number.isNaN(Number.parseInt(numCode))) {
          return code.substr(0, code.length - 2) + '0' + numCode; // CC1 => CC01
        }
        return code;
      });
      return formattedCodes;
    },
    getStationZones({ zoneStations = [] }) {
      return this.zones.filter(z => zoneStations.some(zs => zs.zoneId === z.zoneId))
    },
    getStationGroups(station) {
      const groupIds = this.getStationZones(station).map(z => z.groupId)
      return this.groups.filter(g => groupIds.includes(g.groupId))
    },
    getStationZoneNames(station) {
      const zoneNames = [ ...new Set(this.getStationZones(station).map(z => z.zoneName))];
      return zoneNames.join(', ')
    },
    getStationGroupNames(station) {
      const stationGroupNames = [ ...new Set(this.getStationGroups(station).map(z => z.groupName))];
      return stationGroupNames.join(', ')
    },
  }
}
</script>
<style scoped>
.ShortCodeBlock {
  @apply flex
}

.ShortCode {
  @apply py-1 px-3 text-xs font-semibold bg-gray-400 text-white tracking-wider
}


/* @media screen and (max-width: 768px) {
  .ShortCodeBlock {
    @apply block;
  }

  .ShortCode {
    @apply rounded;
  }
} */

[class*='ShortCode--CC'],
[class*='ShortCode--CE'] {
  @apply bg-yellow-400
}

[class*='ShortCode--NS'] {
  @apply bg-red-400
}

[class*='ShortCode--EW'],
[class*='ShortCode--CG'] {
  @apply bg-green-400
}

[class*='ShortCode--DT'] {
  @apply bg-blue-400
}

[class*='ShortCode--NE'] {
  @apply bg-purple-400
}

[class*='ShortCode--TE'] {
  background-color: #9D5B25;
}

.ShortCode:first-child {
  @apply rounded-l-md
}

.ShortCode:last-child {
  @apply rounded-r-md
}

</style>
<style lang="scss">
.smrt_stations .AsomCard__Body{
  @apply rounded-br-md rounded-bl-md
}
.tbl_smrtStation{ 
  thead th:first-child > div{
    @apply justify-center
  }
  tbody td:first-child{
    @apply text-center
  }
  tbody td:nth-child(2){
    @apply font-semibold text-gray-900
  }
}
</style>
